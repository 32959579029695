import React from 'react';
import { GridRenderCellParams, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { ReduxTenant } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';

export const PrimaryTenantCell = (props: GridRenderCellParams<ReduxTenant>) => {
  if (props.row.isMainTenant) {
    return <CheckIcon />;
  } else return '';
};

export function PrimaryTenantEditCell(props: GridRenderEditCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    apiRef.current?.setEditCellValue({ id, field, value: newValue });
  };

  return <Checkbox checked={value} size={'small'} onChange={handleChange}></Checkbox>;
}
