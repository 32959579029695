import React from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { DetailStatusSelect } from '../DetailStatusSelect';
import { useDispatch } from 'react-redux';
import { SelectChangeEvent } from '@mui/material/Select';
import { DetailStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { updateSubDetailAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import { ReduxDetail } from '@monkeyjump-labs/cam-fe-shared/dist/types/detailTypes';

export const StatusEditCell = (params: GridRenderEditCellParams<ReduxDetail, DetailStatus>) => {
  const dispatch = useDispatch();
  const { id, field } = params;
  const apiRef = useGridApiContext();
  const handleChange = (e: SelectChangeEvent) => {
    dispatch(updateSubDetailAction({ body: { ...params.row, status: e.target.value as DetailStatus } }));
    apiRef.current?.setEditCellValue({ id, field, value: e.target.value as DetailStatus });
    apiRef.current?.stopCellEditMode({ id, field });
  };

  return <DetailStatusSelect onChange={handleChange} value={params.value} size={'small'} />;
};
