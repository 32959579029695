import { all, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  AddForecastHandlerRequest,
  AddForecastHandlerResponse,
  ListForecastsHandlerResponse,
  UpdateManualBudgetEntryHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  addForecastAction,
  addForecastActionSuccess,
  getForecastAction,
  getForecastFailAction,
  getForecastSuccessAction,
  listForecastsAction,
  listForecastsFailAction,
  listForecastsSuccessAction,
  updateManualBudgeEntryMonthAction,
  updateManualBudgeEntryMonthSuccessAction,
} from './budgetAndForecastSlice';
import { ReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { mapReduxSlimForecast } from './budgetAndForecastTypes';

function* getForecast(action: PayloadAction<{ id: string }>) {
  try {
    const response: AddForecastHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().forecast_GetForecast,
      action.payload.id,
    );

    yield put(getForecastSuccessAction(response));
  } catch (error) {
    yield put(getForecastFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to get forecast' }));
  }
}

function* updateManualBudgetEntryMonth(
  action: PayloadAction<{ id: string; accountId: string; dateKey: string; amount: number }>,
) {
  try {
    console.log('stuff', action.payload);
    const body = UpdateManualBudgetEntryHandlerRequest.fromJS({
      accountId: action.payload.accountId,
      dateKey: action.payload.dateKey,
      amount: action.payload.amount,
    });

    yield apiCall(ApiClientSingleton.getInstance().forecast_UpdateManualBudgetEntry, action.payload.id, body);
    yield put(showToastMessageAction({ message: 'Updated successfully' }));
    yield put(updateManualBudgeEntryMonthSuccessAction(action.payload));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to update budget' }));
  }
}

function* addForecast(
  action: PayloadAction<{ propertyId: string; name: string; startDate: ReduxDate; endDate: ReduxDate }>,
) {
  try {
    const body = AddForecastHandlerRequest.fromJS({
      name: action.payload.name,
      startDate: toStandardDate(action.payload.startDate),
      endDate: toStandardDate(action.payload.endDate),
    });

    const response: AddForecastHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().forecast_AddForecast,
      action.payload.propertyId,
      body,
    );

    if (response.toJSON().id === undefined) {
      yield put(
        showErrorAction({
          error: Error('ID not returned, failed to add forecast'),
          fallbackMessage: 'Failed to add forecast',
        }),
      );
      return;
    }

    yield put(addForecastActionSuccess({ id: response.toJSON().id, ...action.payload }));
    yield put(showToastMessageAction({ message: 'Forecast added successfully' }));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to add forecast' }));
  }
}

function* listForecasts(action: PayloadAction<{ propertyId: string }>) {
  try {
    const response: ListForecastsHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().forecast_ListForecasts,
      action.payload.propertyId,
    );

    console.log(response, 'and', response.toJSON());
    yield put(listForecastsSuccessAction(response.toJSON().forecasts?.map(mapReduxSlimForecast)));
  } catch (error) {
    yield put(listForecastsFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Failed to load forecasts' }));
  }
}

export function* budgetAndForecastSagas() {
  yield all([
    takeLatest(getForecastAction.type, getForecast),
    takeLatest(updateManualBudgeEntryMonthAction.type, updateManualBudgetEntryMonth),
    takeLatest(addForecastAction.type, addForecast),
    takeLatest(listForecastsAction.type, listForecasts),
  ]);
}
