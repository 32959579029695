import React, { FC, useEffect, useState } from 'react';
import { ReduxChangeOrder } from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import TextField from '@mui/material/TextField';
import { ItemizedPurchaseOrderItemTable } from '../expenseElementTables/ItemizedPurchaseOrderItemTable';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

export type POChangeOrderProps = {
  changeOrder: ReduxChangeOrder;
  loading: boolean;
  onUpdateChangeOrder: (changeOrder: ReduxChangeOrder) => void;
  onPOLineItemAssociation?: (lineItemNumber: string) => void;
  gridApiRef?: React.RefObject<GridApiPremium>;
};

export const POChangeOrder: FC<POChangeOrderProps> = ({
  changeOrder,
  loading,
  onUpdateChangeOrder,
  onPOLineItemAssociation,
  gridApiRef,
}) => {
  const [editingChangeOrder, setEditingChangeOrder] = useState<ReduxChangeOrder>({ ...changeOrder });
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(false);
    setEditingChangeOrder({ ...changeOrder });
  }, [changeOrder]);

  useEffect(() => {
    setIsDirty(JSON.stringify(editingChangeOrder) !== JSON.stringify(changeOrder));
  }, [editingChangeOrder]);

  const handleUpdateChangeOrder = () => {
    setIsDirty(false);
    onUpdateChangeOrder(editingChangeOrder);
  };

  const getTotal = (order: ReduxChangeOrder) => {
    return order.isItemized
      ? (order.items?.reduce((acc, curr) => acc + (curr.amount ?? 0) * (curr.quantity ?? 1), 0)?.toFixed(2) ?? 0)
      : order.amount;
  };

  return (
    <Accordion
      sx={{ width: '100%', border: '.1rem solid gray' }}
      elevation={0}
      expanded={isExpanded}
      onChange={(_, expanded) => setIsExpanded(expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} spacing={'1rem'} alignItems={'center'}>
            <Typography sx={{ fontWeight: 'bold' }}>Total Amount: </Typography>
            {isExpanded && !changeOrder.isItemized ? (
              <NumberValueTextField
                valueUnits={'dollars'}
                allowNegativeValues
                value={editingChangeOrder.amount !== 0 ? editingChangeOrder.amount : ''}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setEditingChangeOrder({ ...editingChangeOrder, amount: Number(e.target.value) });
                }}
              />
            ) : (
              <Typography>${getTotal(editingChangeOrder)}</Typography>
            )}
          </Stack>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ display: 'flex', justifyContent: 'right', padding: '1rem' }}
          >
            {editingChangeOrder.createdBy + ' '}
            {' — '}
            {editingChangeOrder.dateCreated ? tryFormatDate(editingChangeOrder.dateCreated) : ''}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <>
          <TextField
            fullWidth
            label={'Description'}
            value={editingChangeOrder.note}
            variant={'outlined'}
            multiline
            onChange={(e) => {
              setEditingChangeOrder({ ...editingChangeOrder, note: e.target.value });
            }}
          />
          {editingChangeOrder.isItemized && (
            <ItemizedPurchaseOrderItemTable
              value={editingChangeOrder.items ?? []}
              onSave={(items) => {
                setEditingChangeOrder({ ...editingChangeOrder, items: items });
              }}
              isEditable
              onPOLineItemAssociation={onPOLineItemAssociation}
              changeOrderId={changeOrder.id}
              gridApiRef={gridApiRef}
            />
          )}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', pt: '1rem' }}>
            <LoadingButton
              variant={'contained'}
              disabled={!isDirty}
              loading={loading}
              onClick={handleUpdateChangeOrder}
            >
              Save changes
            </LoadingButton>
          </Box>
        </>
      </AccordionDetails>
    </Accordion>
  );
};
