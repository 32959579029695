import React, { FC, useEffect, useState } from 'react';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import { BudgetAndForecastTable } from './BudgetAndForecastTable';
import {
  addForecastAction,
  getForecastAction,
  listForecastsAction,
  useBudgetAndForecast,
} from '../redux/budgetAndForecastSlice';
import { useDispatch } from 'react-redux';
import { toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DialogLayout } from '../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers-pro';
import DialogActions from '@mui/material/DialogActions';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import Autocomplete from '@mui/material/Autocomplete';
import { ReduxSlimForecast } from '../redux/budgetAndForecastTypes';

export const BudgetAndForecastTab: FC = () => {
  const { selectedContext } = useAssets();
  const { currentForecast, forecasts } = useBudgetAndForecast();
  const dispatch = useDispatch();
  const [openAddForecastDialog, setOpenAddForecastDialog] = useState(false);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedForecast, setSelectedForecast] = useState<ReduxSlimForecast | null>(null);

  useEffect(() => {
    if (selectedContext.propertyId) {
      dispatch(listForecastsAction({ propertyId: selectedContext.propertyId }));
    }
  }, []);

  useEffect(() => {
    if (selectedForecast?.id) {
      dispatch(getForecastAction({ id: selectedForecast.id }));
    }
  }, [selectedForecast]);

  const onClose = () => {
    setOpenAddForecastDialog(false);
    setName('');
    setStartDate(null);
    setEndDate(null);
  };

  const onSubmit = () => {
    if (!selectedContext.propertyId || !startDate || !endDate) return;

    dispatch(
      addForecastAction({
        propertyId: selectedContext.propertyId,
        name,
        startDate: toReduxDate(startDate),
        endDate: toReduxDate(endDate),
      }),
    );
    setOpenAddForecastDialog(false);
    setName('');
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <StyledInfoBox label="Budget and Forecast">
      <Stack direction={'column'} spacing={2}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Button variant="contained" color="primary" onClick={() => setOpenAddForecastDialog(true)}>
            Add Forecast
          </Button>
          <Autocomplete
            fullWidth
            options={forecasts.value ?? []}
            renderInput={(params) => <TextField {...params} label="Select Forecast" />}
            getOptionLabel={(option) => option.name ?? ''}
            onChange={(e, value) => {
              if (value) {
                setSelectedForecast(value);
              }
            }}
            value={selectedForecast}
          />
        </Stack>
        <BudgetAndForecastTable
          forecast={currentForecast.value ?? {}}
          totalCount={0}
          page={1}
          pageSize={10}
          setPage={() => {}}
          setPageSize={() => {}}
          onSortModelChange={() => {}}
          loading={currentForecast.loading}
        />
      </Stack>
      <DialogLayout title={'Add Forecast'} onClose={onClose} open={openAddForecastDialog}>
        <DialogContent>
          <Box pt={2}>
            <Stack direction={'column'} spacing={2}>
              <TextField label="Name" fullWidth onChange={(e) => setName(e.target.value)} />
              <Stack direction={'row'} justifyContent={'space-between'}>
                <DatePicker
                  label={'Start Date'}
                  views={['year', 'month']}
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                />
                <DatePicker
                  label={'End Date'}
                  views={['year', 'month']}
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant={'contained'} onClick={onSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </DialogLayout>
    </StyledInfoBox>
  );
};
