import React, { FC, useEffect, useState } from 'react';
import { IForecastLineDto } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Box from '@mui/material/Box';
import { StripedDataGrid } from '../../datagrids/StripedDataGrid';
import {
  GridCellEditStopParams,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
  MuiEvent,
} from '@mui/x-data-grid-premium';
import { ReduxForecast } from '../redux/budgetAndForecastTypes';
import { useDispatch } from 'react-redux';
import { updateManualBudgeEntryMonthAction } from '../redux/budgetAndForecastSlice';
import { format } from 'date-fns';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { currencyFormatter } from '../../utils/currencyFormatter';

type BudgetAndForecastTableProps = {
  forecast: ReduxForecast;
  totalCount: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  loading: boolean;
};

export const BudgetAndForecastTable: FC<BudgetAndForecastTableProps> = ({
  forecast,
  totalCount,
  page,
  pageSize,
  setPage,
  setPageSize,
  onSortModelChange,
  loading,
}) => {
  const [budgetRows, setBudgetRows] = useState<IForecastLineDto[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (forecast?.manualBudgetEntries) {
      setBudgetRows(forecast.manualBudgetEntries);
    }
  }, [forecast]);

  const getter = (month: string) => (param: never, row: IForecastLineDto) =>
    !row?.allotment ? 0 : (row.allotment[month] ?? 0);

  const getMonths = (): GridColDef<IForecastLineDto>[] => {
    if (!forecast.startDate || !forecast.endDate) return [];

    const start = toStandardDate(forecast.startDate);
    const end = toStandardDate(forecast.endDate);

    const months = [];

    while (start <= end) {
      months.push({
        key: format(start, 'yyyy-MM'),
        label: format(start, 'MMM yy'),
      });

      start.setMonth(start.getMonth() + 1);
    }

    return months.map((month): GridColDef<IForecastLineDto> => {
      return {
        field: month.key,
        renderHeader: () => month.label,
        type: 'number',
        display: 'flex',
        flex: 1,
        valueGetter: getter(month.key),
        valueFormatter: (value) => currencyFormatter.format(value),
        editable: true,
      };
    });
  };

  const columns: GridColDef<IForecastLineDto>[] = [
    {
      field: 'name',
      headerName: 'Name',
      display: 'flex',
      flex: 1,
      valueGetter: (param, row) => row?.label ?? '',
    },
    ...getMonths(),
  ];

  const cellEdit = (entry: IForecastLineDto, dateKey: string, newValue: number) => {
    if (!forecast.id || !entry.account?.id) return;
    dispatch(
      updateManualBudgeEntryMonthAction({
        id: forecast.id,
        accountId: entry.account.id,
        dateKey: dateKey,
        amount: newValue,
      }),
    );
  };

  return (
    <Box
      sx={{
        minHeight: 450,
        width: 1,
      }}
    >
      <StripedDataGrid
        disableRowGrouping
        autoHeight
        rows={budgetRows}
        columns={columns}
        rowCount={totalCount}
        pagination
        paginationMode={'server'}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={{ page: page, pageSize: pageSize }}
        sortingMode={'server'}
        onSortModelChange={onSortModelChange}
        onPaginationModelChange={(model: GridPaginationModel) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        sx={{
          minHeight: 450,
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
        getRowId={(x) => x?.account?.id ?? x.label}
        loading={loading}
        getRowHeight={() => 'auto'}
        defaultGroupingExpansionDepth={-1}
        groupingColDef={{ flex: 3 }}
        isCellEditable={(params) => !!params.row.account}
        onCellEditStop={(param: GridCellEditStopParams, event: MuiEvent) => {
          console.log('owerjiaeowr', param, event);
          const updatedEvent = event as MuiEvent<React.BaseSyntheticEvent>;
          if (!updatedEvent || !param.field || updatedEvent.target?.value === undefined) return;

          if (updatedEvent.type === 'keydown') {
            const keydownEvent = updatedEvent as MuiEvent<React.KeyboardEvent>;
            if (!keydownEvent || keydownEvent.key !== 'Enter') return;
          }

          cellEdit(param.row, param.field, parseFloat(updatedEvent.target.value));
        }}
        onCellSelectionModelChange={() => {}}
      />
    </Box>
  );
};
