import {
  IForecastDto,
  ISlimForecastDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

import { ReduxDate, toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export type ReduxForecast = Omit<IForecastDto, 'startDate' | 'endDate'> & {
  startDate?: ReduxDate;
  endDate?: ReduxDate;
};

export type ReduxSlimForecast = Omit<ISlimForecastDto, 'startDate' | 'endDate'> & {
  startDate?: ReduxDate;
  endDate?: ReduxDate;
};

export function mapReduxForecast(forecast: IForecastDto): ReduxForecast {
  return {
    ...forecast,
    startDate: toReduxDate(forecast.startDate),
    endDate: toReduxDate(forecast.endDate),
  };
}
export function mapReduxSlimForecast(forecast: ISlimForecastDto): ReduxSlimForecast {
  return {
    ...forecast,
    startDate: toReduxDate(forecast.startDate),
    endDate: toReduxDate(forecast.endDate),
  };
}
