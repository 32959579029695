import React, { FC, useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ListingCard } from '../shared/ListingCard';
import {
  AssociationType,
  ListingType,
  QueryOperator,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useTheme } from '@mui/material';
import { DrawerNavItem } from '../../../global/layout/DrawerLayout';
import { useIcons } from '../../../_shared/icons/useIcons';
import { AdditionalDetails } from './listingTabs/AdditionalDetails';
import { getAssetTypeFromPathname } from '../../../_shared/utils/getAssetTypeFromPathname';
import { ListingsPage } from '../webView/ListingsPage';
import { ListingParameter, useListingFilters } from '../../redux/useListingFilters';
import { ListingsSearch } from '../shared/search/ListingsSearch';
import { listListingsAction, useListings } from '../../redux/listingSlice';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import LinearProgress from '@mui/material/LinearProgress';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';

enum PropertyBuildingListingTab {
  'templates',
  'units',
  'property',
  'webView',
}

export const PropertyBuildingListingTabs: FC = () => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { listings } = useListings();
  const theme = useTheme();
  const { getAssociationIcon, getTabIcon } = useIcons();
  const [selectedTab, setSelectedTab] = React.useState<PropertyBuildingListingTab>(
    PropertyBuildingListingTab.templates,
  );
  const { handleUpdateFilters } = useListingFilters();
  const [filters, setFilters] = useState<ListingParameter[]>([]);

  useEffect(() => {
    if (!selectedContext.propertyId) return;
    if (selectedContext.unitId) return;
    const parameters: ListingParameter[] = selectedContext.buildingId
      ? [
          ...filters,
          {
            value: `%${selectedContext.buildingId}%`,
            operator: QueryOperator.Like,
            field: 'path',
          },
        ]
      : filters;
    selectedContext.propertyId &&
      dispatch(listListingsAction({ propertyId: selectedContext.propertyId, parameters: parameters }));
  }, [filters]);

  const handleSetFilter = (newFilters: ListingParameter[]) => {
    setFilters(handleUpdateFilters(newFilters, filters));
  };

  const onTabChange = (newValue: PropertyBuildingListingTab) => {
    setSelectedTab(newValue);
  };

  const templateListings = listings.value?.filter((listing) => listing.type === ListingType.Generic) ?? [];
  const unitListings = listings.value?.filter((listing) => listing.type === ListingType.Unit) ?? [];

  const t: (Omit<DrawerNavItem, 'value'> & { value: PropertyBuildingListingTab })[] = [
    {
      icon: <OtherHousesIcon />,
      label: 'Generic Listings',
      value: PropertyBuildingListingTab.templates,
    },
    {
      icon: getAssociationIcon(AssociationType.BuildingUnit),
      label: 'Unit Listings',
      value: PropertyBuildingListingTab.units,
    },
    {
      icon: getAssociationIcon(AssociationType.RentalProperty),
      label: 'Property Advertising Config',
      value: PropertyBuildingListingTab.property,
    },
    {
      icon: getTabIcon('webView'),
      label: 'Web View',
      value: PropertyBuildingListingTab.webView,
    },
  ];

  const tabs =
    getAssetTypeFromPathname() === 'building'
      ? t.filter(
          (tab) => tab.value === PropertyBuildingListingTab.units || tab.value === PropertyBuildingListingTab.templates,
        )
      : t;

  return (
    <TabContext value={selectedTab}>
      <Tabs
        onChange={(e, v) => onTabChange(v)}
        variant="scrollable"
        scrollButtons="auto"
        aria-label={`property-advertise-tabs`}
        value={selectedTab}
      >
        {tabs.map((tab) => (
          <Tooltip title={tab.label} key={tab.value}>
            <Tab
              icon={tab.icon}
              value={tab.value}
              aria-selected={selectedTab === tab.value}
              sx={{
                backgroundColor: selectedTab === tab.value ? theme.palette.secondary.light : 'inherit',
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,
              }}
            />
          </Tooltip>
        ))}
      </Tabs>
      <Divider />
      <Grid container spacing={1}>
        {selectedTab === PropertyBuildingListingTab.templates || selectedTab === PropertyBuildingListingTab.units ? (
          <Grid item xs={12} mt={1}>
            <ListingsSearch onClearFilters={() => setFilters([])} onSetFilter={handleSetFilter} isPropertyView />
          </Grid>
        ) : null}
        {
          {
            0: listings.loading ? (
              <LinearProgress />
            ) : templateListings.length === 0 ? (
              <Box p={2}>
                <Typography fontStyle={'italic'}>No Generic Listings</Typography>
              </Box>
            ) : (
              templateListings.map((listing) => (
                <Grid item xs={4} key={listing.id}>
                  <ListingCard listing={listing} isPropertyView />
                </Grid>
              ))
            ),
            1: listings.loading ? (
              <LinearProgress />
            ) : unitListings.length === 0 ? (
              <Box p={2}>
                <Typography fontStyle={'italic'}>No Unit Listings</Typography>
              </Box>
            ) : (
              unitListings.map((listing) => (
                <Grid item xs={4} key={listing.id}>
                  <ListingCard listing={listing} isPropertyView />
                </Grid>
              ))
            ),
            2: <AdditionalDetails />,
            3: (
              <Box pl={1} width={'100%'}>
                <ListingsPage isPropertyPreview />
              </Box>
            ),
          }[selectedTab ?? 'templates']
        }
      </Grid>
    </TabContext>
  );
};
