import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Loadable, initLoadable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { IForecastDto } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { mapReduxForecast, ReduxForecast, ReduxSlimForecast } from './budgetAndForecastTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { ReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export interface BudgetAndForecastState {
  currentForecast: Loadable<ReduxForecast>;
  forecasts: Loadable<ReduxSlimForecast[]>;
}

const initialState: BudgetAndForecastState = {
  currentForecast: initLoadable(),
  forecasts: initLoadable(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
export const budgetAndForecastSlice = createSlice({
  name: 'budgetAndForecast',
  initialState: initialState,
  reducers: {
    getForecastAction: (state, action: PayloadAction<{ id: string }>) => {
      state.currentForecast.loading = true;
      state.currentForecast.loaded = false;
    },
    getForecastSuccessAction: (state, action: PayloadAction<IForecastDto>) => {
      state.currentForecast.loading = false;
      state.currentForecast.loaded = true;

      state.currentForecast.value = mapReduxForecast(action.payload);
    },
    getForecastFailAction: (state) => {
      state.currentForecast.loading = false;
      state.currentForecast.loaded = false;
    },
    updateManualBudgeEntryMonthAction: (
      state,
      action: PayloadAction<{ id: string; accountId: string; dateKey: string; amount: number }>,
    ) => {},
    updateManualBudgeEntryMonthSuccessAction: (
      state,
      action: PayloadAction<{ id: string; accountId: string; dateKey: string; amount: number }>,
    ) => {
      if (state.currentForecast.value?.id != action.payload.id) {
        return;
      }

      const entry = state.currentForecast.value?.manualBudgetEntries?.find(
        (e) => e.account?.id === action.payload.accountId,
      );
      if (entry?.allotment) {
        entry.allotment[action.payload.dateKey] = action.payload.amount;
      }
    },
    addForecastAction: (
      state,
      action: PayloadAction<{ propertyId: string; name: string; startDate: ReduxDate; endDate: ReduxDate }>,
    ) => {},
    addForecastActionSuccess: (
      state,
      action: PayloadAction<{ id: string; name: string; startDate: ReduxDate; endDate: ReduxDate }>,
    ) => {
      state.forecasts.value?.push(action.payload);
    },
    listForecastsAction: (state, action: PayloadAction<{ propertyId: string }>) => {
      state.forecasts.loading = true;
      state.forecasts.loaded = false;
    },
    listForecastsSuccessAction: (state, action: PayloadAction<ReduxSlimForecast[]>) => {
      state.forecasts.loading = false;
      state.forecasts.loaded = true;
      state.forecasts.value = action.payload;
    },
    listForecastsFailAction: (state) => {
      state.forecasts.loading = false;
      state.forecasts.loaded = false;
    },
  },
});

export const {
  getForecastAction,
  getForecastSuccessAction,
  getForecastFailAction,
  updateManualBudgeEntryMonthAction,
  updateManualBudgeEntryMonthSuccessAction,
  addForecastAction,
  addForecastActionSuccess,
  listForecastsAction,
  listForecastsSuccessAction,
  listForecastsFailAction,
} = budgetAndForecastSlice.actions;

export const useBudgetAndForecast = () => useSelector((r: RootState) => r.budgetAndForecast);
