import React, { useEffect, useState } from 'react';
import { StripedDataGrid, styledFilterPanelProps } from '../../../../_shared/datagrids/StripedDataGrid';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { updateApplicationStatusAction } from '../../../redux/applicationSlice';
import { useDispatch } from 'react-redux';
import { AppStatusCustomCell, AppStatusCustomEditCell, AppStatusFilterCell } from './AppStatusCustomCell';
import { tryFormatDate } from '../../../../_shared/utils/TryFormatDate';
import {
  AssociationType,
  ICreateApplicationResponse,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { RenderGridCellExpand } from '../../../../_shared/datagrids/RenderGridCellExpand';
import { ReduxApplication } from '../../../redux/applicationTypes';
import { dateFilters, stringFilters } from '../../../../_shared/utils/filteringUtils';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EmailIcon from '@mui/icons-material/Email';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ToolbarWithAction } from '../../../../_shared/datagrids/ToolbarWithAction';
import { EmailAssociationInfo, EmailType } from '@monkeyjump-labs/cam-fe-shared/dist/types/communicationTypes';
import {
  setDetailedEmailInfoAction,
  setOpenEmailDialogAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import { ActionCell } from '../../../../_shared/datagrids/ActionCell';
import { endpointIsntIntegratedAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

type Props = {
  rows: ReduxApplication[];
  loading?: boolean;
  onOpenEditApplicant: (id: GridRowId) => void;
  onSendApplication: (id: GridRowId) => any;
  columnVisibility: GridColumnVisibilityModel;
  checkboxSelection: boolean;
  editingInWizard?: boolean;
  selectedRows?: ReduxApplication[];
  onSelectedRowsChange?: (rows: ReduxApplication[]) => any;
  disableColumnFilter?: boolean;
  onFilterModelChange?: (model: GridFilterModel) => void;
  pagination: boolean;
  rowCount?: number;
  page?: number;
  pageSize?: number;
  onPageChange?: (newPage: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
  onSortModelChange?: (model: GridSortModel) => void;
  onOpenCreateLease?: (individualApplicationId?: GridRowId) => void;
};

export const ApplicantTable = ({
  rows,
  loading,
  onOpenEditApplicant,
  onSendApplication,
  columnVisibility,
  checkboxSelection,
  editingInWizard,
  selectedRows,
  onSelectedRowsChange,
  disableColumnFilter,
  onFilterModelChange,
  pagination,
  rowCount,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onSortModelChange,
  onOpenCreateLease,
}: Props) => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const selectedApplicantEmailObjects: EmailAssociationInfo[] =
      selectedRows?.flatMap((application) =>
        application.contactInfo?.email
          ? [
              {
                associatedId: application.id,
                associationType: AssociationType.Application,
                emailAddresses: [application.contactInfo.email],
                label: `${application.firstName} ${application.lastName}`,
              },
            ]
          : [],
      ) ?? [];
    dispatch(setDetailedEmailInfoAction({ recipients: selectedApplicantEmailObjects }));
    setSelectionModel(selectedRows?.map((row) => row.id!) ?? []);
  }, [selectedRows]);

  const handleSelectionChange = (model: GridRowSelectionModel) => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange(model.map((row) => rows.find((r) => r.id === row)!));
    }
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    if (newRow.applicationStatus !== oldRow.applicationStatus) {
      dispatch(
        updateApplicationStatusAction({
          id: newRow.id,
          applicationStatus: newRow.applicationStatus,
          rentalPropertyId: newRow.rentalPropertyId,
        }),
      );
      return newRow;
    } else return oldRow;
  };

  const handleEmailApplicant = (params: GridRowParams<ReduxApplication & ICreateApplicationResponse>) => {
    const recipientInfo =
      params.row.id && params.row.contactInfo?.email
        ? [
            {
              associatedId: params.row.id,
              associationType: AssociationType.Application,
              emailAddresses: [params.row.contactInfo.email],
              label: `${params.row.firstName} ${params.row.lastName}`,
            },
          ]
        : [];
    dispatch(setDetailedEmailInfoAction({ recipients: recipientInfo }));
    dispatch(setOpenEmailDialogAction(EmailType.Bulk));
  };

  const renderAppStatusHeader = () => {
    if (editingInWizard) {
      return <>App Status</>;
    } else
      return (
        <>
          {'App Status '}
          <EditIcon fontSize="small" color="disabled" />
        </>
      );
  };

  function* createActions(params: GridRowParams) {
    if (onOpenCreateLease) {
      yield (
        <ActionCell
          icon={<ArrowForwardIcon />}
          key={'createLease'}
          label="Create Lease"
          onClick={() => onOpenCreateLease(params.id)}
          showInMenu
        />
      );
    }
    yield (
      <ActionCell
        icon={<ScheduleIcon />}
        key={'scheduleShowing'}
        label="Schedule Showing"
        onClick={() => dispatch(endpointIsntIntegratedAction())}
        showInMenu
      />
    );
    yield (
      <ActionCell
        icon={<EditIcon />}
        key={'startApplication'}
        label="Start Application"
        onClick={() => onSendApplication(params.id)}
        showInMenu
      />
    );
    yield (
      <ActionCell
        icon={<EmailIcon />}
        key={'emailApplicant'}
        label="Email Applicant"
        onClick={() => handleEmailApplicant(params)}
        showInMenu
        disabled={params.row.contactInfo ? !params.row.contactInfo.email : false}
        disabledLabel={'No Available Email'}
      />
    );
  }

  const columns: GridColDef<ReduxApplication>[] = [
    {
      field: 'dateSubmitted',
      headerName: 'Submitted',
      type: 'date',
      flex: 0.75,
      editable: false,
      filterOperators: dateFilters,
      valueFormatter: (value?: string) => {
        return value ? tryFormatDate(value) : '';
      },
    },
    {
      field: 'firstName',
      headerName: 'Applicant',
      sortable: false,
      flex: 1.25,
      display: 'flex',
      editable: false,
      filterOperators: stringFilters,
      renderCell: (params: GridRenderCellParams) => {
        if (editingInWizard) {
          return <Typography variant="body2">{params.row.firstName + ' ' + params.row.lastName}</Typography>;
        } else
          return (
            <Tooltip title={'View/Add Applicant Details'}>
              <Link sx={{ cursor: 'pointer' }} color="inherit">
                <Typography variant="body2" onClick={() => onOpenEditApplicant(params.row.id)}>
                  {params.row.firstName + ' ' + params.row.lastName}
                </Typography>
              </Link>
            </Tooltip>
          );
      },
    },
    {
      field: 'unitRequested',
      headerName: 'Unit Req',
      flex: 0.75,
      editable: false,
      filterOperators: stringFilters,
      renderCell: (params: GridRenderCellParams<ReduxApplication, string>) => {
        return <RenderGridCellExpand {...params} />;
      },
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 0.75,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (value, row: ReduxApplication) => row.contactInfo?.phone,
      renderCell: (params: GridRenderCellParams<ReduxApplication, string>) => {
        return <RenderGridCellExpand {...params} />;
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 0.75,
      editable: false,
      filterable: false,
      sortable: false,
      valueGetter: (value, row: ReduxApplication) => row.contactInfo?.email,
      renderCell: (params: GridRenderCellParams<ReduxApplication, string>) => {
        return <RenderGridCellExpand {...params} />;
      },
    },
    {
      field: 'requestedMoveInDate',
      headerName: 'Move-In',
      type: 'date',
      flex: 0.75,
      editable: false,
      filterOperators: dateFilters,
      valueFormatter: (value?: string) => {
        return value ? tryFormatDate(value) : '';
      },
    },
    {
      field: 'applicationStatus',
      flex: 1,
      display: 'flex',
      editable: !editingInWizard,
      filterOperators: stringFilters.map((operator) => ({
        ...operator,
        InputComponent: AppStatusFilterCell,
      })),
      renderHeader: renderAppStatusHeader,
      renderCell: (params: GridRenderCellParams) => {
        return <AppStatusCustomCell {...params} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <AppStatusCustomEditCell {...params} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.25,
      type: 'actions',
      getActions: (params) => Array.from(createActions(params)),
    },
  ];

  return (
    <StripedDataGrid
      disableRowGrouping
      loading={loading}
      rows={rows}
      columns={columns}
      checkboxSelection={checkboxSelection}
      rowSelection
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionChange}
      disableColumnFilter={disableColumnFilter ? disableColumnFilter : false}
      slots={{ toolbar: ToolbarWithAction }}
      slotProps={{
        toolbar: {
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
          emailType: EmailType.Bulk,
        },
        filterPanel: styledFilterPanelProps,
      }}
      onFilterModelChange={onFilterModelChange}
      filterMode={'server'}
      sortingMode={onSortModelChange ? 'server' : 'client'}
      onSortModelChange={onSortModelChange}
      initialState={{
        columns: {
          columnVisibilityModel: columnVisibility,
        },
      }}
      processRowUpdate={processRowUpdate}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      pagination={pagination}
      paginationMode={'server'}
      rowCount={rowCount}
      pageSizeOptions={[10, 25, 50, 100]}
      paginationModel={{ page: page ?? 0, pageSize: pageSize ?? 10 }}
      onPaginationModelChange={(model: GridPaginationModel) => {
        onPageChange && onPageChange(model.page);
        onPageSizeChange && onPageSizeChange(model.pageSize);
      }}
    />
  );
};
