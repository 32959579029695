import React from 'react';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';
import { ReduxTask, TaskStatusMap } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { TaskStatusSelect } from '../../TaskStatusSelect';
import {
  AssetType,
  CamTaskStatus,
  GetGroupedTasksHandlerTasksGroupBy,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { updateTaskStatusAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { useDispatch } from 'react-redux';

export const TaskStatusCell = (props: GridRenderCellParams) => {
  return (
    <>
      {Array.from(TaskStatusMap).map(([status, label]) =>
        status === props.value ? (
          <Typography variant="body2" key={status}>
            {label}
          </Typography>
        ) : (
          <Typography key={status}>{''}</Typography>
        ),
      )}
    </>
  );
};

type TaskStatusEditCellProps = {
  groupingType: GetGroupedTasksHandlerTasksGroupBy | undefined;
  parentRowId: string | undefined;
  includeClosed: boolean;
} & GridRenderCellParams<ReduxTask, string>;

export const TaskStatusEditCell = ({ groupingType, parentRowId, includeClosed, ...props }: TaskStatusEditCellProps) => {
  const dispatch = useDispatch();
  const { id, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (e: SelectChangeEvent) => {
    let newValue: CamTaskStatus | undefined;
    Array.from(TaskStatusMap).map(([status]) => {
      if (e.target.value === status) {
        newValue = status;
        dispatch(
          updateTaskStatusAction({
            id: props.row.id!,
            associatedId:
              !groupingType || groupingType === GetGroupedTasksHandlerTasksGroupBy.Asset
                ? props.row.associatedId!
                : props.row.propertyId!,
            associationType:
              !groupingType || groupingType === GetGroupedTasksHandlerTasksGroupBy.Asset
                ? props.row.assetType!
                : AssetType.RentalProperty,
            includeClosed: includeClosed,
            body: {
              status: status,
            },
            parentRowId: parentRowId,
          }),
        );
      }
    });
    apiRef.current?.setEditCellValue({ id, field, value: newValue });
    apiRef.current?.stopCellEditMode({ id, field });
  };

  return <TaskStatusSelect value={props.value!} onChange={(e) => handleChange(e)} />;
};
