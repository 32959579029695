import React, { FC, useCallback } from 'react';
import {
  DataGridPremiumProps,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import Box from '@mui/material/Box';
import { dateFilters, stringContainsFilters, stringFilters } from '../../../utils/filteringUtils';
import { UnitNameCell } from './leaseTableCustomCells/UnitNameCell';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { LeaseStatusCell, LeaseStatusFilterCell } from './leaseTableCustomCells/LeaseStatusCells';
import { DelinquencyCell } from './leaseTableCustomCells/DelinquencyCell';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { LeaseActionType, useLeaseActions } from './useLeaseActions';
import { LeaseDispatchType, ReduxLease, ReduxLeaseTerm } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { ActionCell } from '../../../datagrids/ActionCell';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import {
  markLeaseAsActiveAction,
  markLeaseAsEvictionAction,
  markLeaseAsMovedOutAction,
  markLeaseAsPreleasedAction,
  rollbackLeaseToActiveAction,
  uploadSignedLeaseAction,
} from '../../redux/leasesSlice';
import { PaymentOptionsButton } from './PaymentOptionsButton';
import { useMediaQuery } from '@mui/material';
import { appTheme } from '../../../../../AppTheme';
import { useDispatch } from 'react-redux';
import { PaymentTitle } from '../../../dialogs/paymentDialog/LeasePaymentDialog';
import { LeaseDetailPanel } from './LeaseDetailPanel';
import { ToolbarWithAction } from '../../../datagrids/ToolbarWithAction';
import {
  AssociationType,
  LeaseDetailedStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { EmailAssociationInfo, EmailType } from '@monkeyjump-labs/cam-fe-shared/dist/types/communicationTypes';
import {
  setDetailedEmailInfoAction,
  setOpenEmailDialogAction,
  setSelectedAssociationAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import { useIcons } from '../../../icons/useIcons';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';

type LeasesDataGridProps = {
  rows: ReduxLease[];
  rowCount: number;
  loading: boolean | undefined;
  assetId: string;
  leasesType: LeaseDispatchType;
  onRenewLease: (rowId: string) => void;
  handlePaymentOptions: (paymentId: string, propertyId: string, title: PaymentTitle) => void;
  onCancelPreLease: (id: string) => void;
  onCloseLeaseDialog: (id: string, propertyId: string, status: LeaseDetailedStatus) => void;
  selectionModel: GridRowSelectionModel;
  onSelectionChange: (model: GridRowSelectionModel) => void;
  page: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onFilterModelChange: (model: GridFilterModel) => void;
  onSortModelChange: (model: GridSortModel) => void;
};

export const LeasesDataGrid: FC<LeasesDataGridProps> = ({
  rows,
  rowCount,
  loading,
  assetId,
  leasesType,
  onRenewLease,
  handlePaymentOptions,
  onCancelPreLease,
  onCloseLeaseDialog,
  selectionModel,
  onSelectionChange,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onFilterModelChange,
  onSortModelChange,
}) => {
  const dispatch = useDispatch();
  const property = useProperty();
  const { getActionIcon, getAssociationIcon } = useIcons();
  const { getActionsByLeaseStatus } = useLeaseActions(property?.selectedProperty?.value?.currentPropertyDate);
  const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = useCallback(
    ({ row }: GridRowParams<ReduxLease>) => (
      <LeaseDetailPanel row={row} key={row.id} leasesType={leasesType} assetId={assetId} />
    ),
    [],
  );
  const getDetailPanelHeight = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelHeight']>>(
    () => 'auto' as const,
    [],
  );

  const getTenantEmails = (leaseTerms: ReduxLeaseTerm[] | undefined) => {
    return leaseTerms?.flatMap(
      (term) =>
        term.tenantGroup?.tenants.map((tenant) => tenant.contactInfo?.email)?.filter((email) => email) as string[],
    );
  };

  const handleOpenEmailDialog = (emails?: string[], leaseId?: string) => {
    if (!emails || !leaseId) {
      dispatch(setDetailedEmailInfoAction({ recipients: [] }));
      return;
    }
    const tenantEmailObjects: EmailAssociationInfo[] = emails
      .filter((e) => e && leaseId)
      .map((e) => ({
        associatedId: leaseId,
        label: e,
        associationType: AssociationType.Lease,
        emailAddresses: [e],
      }));
    dispatch(setDetailedEmailInfoAction({ recipients: tenantEmailObjects }));
    dispatch(setSelectedAssociationAction({ associatedId: leaseId, associationType: AssociationType.Lease }));
    dispatch(setOpenEmailDialogAction(EmailType.SelectedLeaseOrApplicant));
  };

  function* createActions(params: GridRowParams<ReduxLease>) {
    if (!params.row.id) return;
    const actions = getActionsByLeaseStatus(params.row);
    const breakpointXl = useMediaQuery(appTheme.breakpoints.down('xl'));
    yield (
      <ActionCell
        icon={getAssociationIcon(AssociationType.EmailThread)}
        key={'emailTenants'}
        label={'Email Tenants'}
        onClick={() => {
          const emails = getTenantEmails(params.row.leaseTerms);
          if (emails) {
            handleOpenEmailDialog(emails, params.row.id);
          }
        }}
        disabled={getTenantEmails(params.row.leaseTerms)?.length === 0}
        showInMenu={breakpointXl || leasesType === 'all'}
        disabledLabel={'No Available Email'}
      />
    );
    if (actions?.includes(LeaseActionType.RenewLease)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.RenewLease)}
          key={params.row.moveOutDate ? 'disabledRenewLeaseAction' : 'renewLeaseAction'}
          disabledLabel={'Cannot renew lease with scheduled move-out'}
          label={'Renew Lease'}
          disabled={!!params.row.moveOutDate}
          onClick={() => {
            onRenewLease(params.row.id!);
          }}
          showInMenu={breakpointXl || leasesType === 'all'}
        />
      );
    }
    if (params.row.hasOutstandingDeposit) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.AcceptDeposit)}
          key={'acceptDeposit'}
          label="Accept Deposit"
          className="textPrimary"
          color="inherit"
          onClick={() => {
            if (!params.row.startDate) {
              dispatch(
                showToastMessageAction({
                  message: 'Cannot accept deposit without a start date',
                  severity: 'warning',
                }),
              );
              return;
            }
            handlePaymentOptions(params.row.id!, params.row.propertyId!, 'Accept Deposit');
          }}
          showInMenu={breakpointXl}
        />
      );
    }
    if (actions?.includes(LeaseActionType.MarkAsSigned)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.MarkAsSigned)}
          key={'markLeaseSigned'}
          label="Mark Lease Signed"
          className="textPrimary"
          color="warning"
          onClick={() =>
            params.row.id &&
            params.row.propertyId &&
            dispatch(
              uploadSignedLeaseAction({
                leaseId: params.row.id,
                propertyId: params.row.propertyId,
              }),
            )
          }
          showInMenu={breakpointXl}
        />
      );
    }
    if (actions?.includes(LeaseActionType.PreLease)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.PreLease)}
          key={'preLease'}
          label="Pre-Lease"
          className="textPrimary"
          color="inherit"
          onClick={() => {
            params.row.id &&
              params.row.propertyId &&
              dispatch(
                markLeaseAsPreleasedAction({
                  leaseId: params.row.id,
                  propertyId: params.row.propertyId,
                }),
              );
          }}
          showInMenu={breakpointXl}
        />
      );
    }
    if (actions?.includes(LeaseActionType.MarkAsLeased)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.MarkAsLeased)}
          key={params.row.isUnitAvailable ? 'giveKeys' : 'disabledGiveKeys'}
          label={'Give Keys'}
          disabledLabel={'Unit is Unavailable'}
          className="textPrimary"
          disabled={!params.row.isUnitAvailable}
          color="inherit"
          onClick={() =>
            params.row.id &&
            params.row.unitId &&
            dispatch(
              markLeaseAsActiveAction({
                leaseId: params.row.id,
                assetId: assetId,
                leaseType: leasesType,
              }),
            )
          }
          showInMenu={breakpointXl || leasesType === 'all'}
        />
      );
    }
    if (actions?.includes(LeaseActionType.PaymentOptions)) {
      yield <PaymentOptionsButton {...params} handlePaymentOptions={handlePaymentOptions} />;
    }
    if (actions?.includes(LeaseActionType.CancelPreLease)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.CancelPreLease)}
          disabled={params.row.hasPendingTransactions}
          key={'cancelPreLease'}
          label={'Cancel Pre-Lease'}
          disabledLabel={'Cancel-Pre-Lease Disabled: Pending Transaction'}
          className="textPrimary"
          color="inherit"
          onClick={() => onCancelPreLease(params.row.id!)}
          showInMenu={breakpointXl || leasesType === 'all'}
        />
      );
    }
    if (actions?.includes(LeaseActionType.MoveOut)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.MoveOut)}
          key={'moveOut'}
          label={'Move Out'}
          className="textPrimary"
          color="inherit"
          onClick={() =>
            params.row.id &&
            params.row.unitId &&
            dispatch(
              markLeaseAsMovedOutAction({
                leaseId: params.row.id,
                assetId: assetId,
                leaseType: leasesType,
              }),
            )
          }
          showInMenu={breakpointXl || leasesType === 'all'}
        />
      );
    }
    if (actions?.includes(LeaseActionType.RollbackLease)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.RollbackLease)}
          key={'rollback'}
          label={'Rollback to Active'}
          className="textPrimary"
          color="inherit"
          onClick={() =>
            params.row.id &&
            params.row.unitId &&
            dispatch(
              rollbackLeaseToActiveAction({
                leaseId: params.row.id,
                assetId: assetId,
                leaseType: leasesType,
              }),
            )
          }
          showInMenu={breakpointXl || leasesType === 'all'}
        />
      );
    }
    if (actions?.includes(LeaseActionType.CloseLease)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.CloseLease)}
          disabled={params.row.hasPendingTransactions}
          key="closeLease"
          label={'Close Lease' + (params.row.hasPendingTransactions ? ' - Funds in Process of Return' : '')}
          className="textPrimary"
          color="inherit"
          onClick={() => onCloseLeaseDialog(params.row.id!, params.row.propertyId!, params.row.detailedStatus!)}
          showInMenu={breakpointXl || leasesType === 'all'}
        />
      );
    }
    if (actions?.includes(LeaseActionType.ReturnFunds)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.ReturnFunds)}
          disabled={params.row.hasPendingTransactions}
          key="returnFunds"
          label={'Return Funds' + (params.row.hasPendingTransactions ? ' - Pending Transactions' : '')}
          className="textPrimary"
          color="inherit"
          onClick={() => handlePaymentOptions(params.row.id!, params.row.propertyId!, 'Return Funds')}
          showInMenu={undefined}
        />
      );
    }
    if (actions?.includes(LeaseActionType.RectifyDeposit)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.RectifyDeposit)}
          key="rectifyDeposit"
          label="Rectify Deposit"
          className="textPrimary"
          color="inherit"
          onClick={() => handlePaymentOptions(params.row.id!, params.row.propertyId!, 'Rectify Deposit')}
          showInMenu={undefined}
        />
      );
    }
    if (actions?.includes(LeaseActionType.BeginEviction)) {
      yield (
        <ActionCell
          icon={getActionIcon(LeaseActionType.BeginEviction)}
          key={'beginEviction'}
          label={'Begin Eviction'}
          className="textPrimary"
          color="inherit"
          onClick={() =>
            params.row.id &&
            params.row.unitId &&
            dispatch(
              markLeaseAsEvictionAction({
                leaseId: params.row.id,
                assetId: assetId,
                leaseType: leasesType,
              }),
            )
          }
          showInMenu={breakpointXl || leasesType === 'all'}
        />
      );
    }
  }

  const columns: GridColDef<ReduxLease>[] = [
    {
      field: 'tenantName',
      type: 'string',
      headerName: 'Tenant Name',
      align: 'left',
      flex: 1.5,
      filterOperators: stringContainsFilters,
      renderCell: (params: GridRenderCellParams<ReduxLease>) => {
        if (typeof params.row?.leaseTerms !== 'undefined' && (params.row?.leaseTerms?.length ?? 0) >= 1) {
          const firstLeaseTerm = params.row.leaseTerms[params.row.leaseTerms.length - 1];
          const firstTenant = firstLeaseTerm?.tenantGroup?.tenants[0];
          return firstTenant?.firstName + ' ' + firstTenant?.lastName;
        }
        return '';
      },
    },
    {
      field: 'unitName',
      type: 'string',
      headerName: 'Unit',
      align: 'left',
      flex: 1.5,
      display: 'flex',
      filterOperators: stringFilters,
      renderCell: (params: GridRenderCellParams) => {
        return <UnitNameCell {...params} leasesType={leasesType} />;
      },
    },
    {
      field: 'startDate',
      type: 'date',
      headerName: 'Start Date',
      align: 'center',
      flex: 1,
      valueFormatter: (value?: string) => {
        return value ? tryFormatDate(value) : '';
      },
      filterOperators: dateFilters,
      sortable: false,
    },
    {
      field: 'endDate',
      type: 'date',
      headerName: 'End Date',
      align: 'center',
      flex: 1,
      valueFormatter: (value?: string) => {
        return value ? tryFormatDate(value) : '';
      },
      filterOperators: dateFilters,
      sortable: false,
    },
    {
      field: 'detailedStatus',
      headerName: 'Lease Status',
      align: 'center',
      flex: 1.25,
      filterOperators: stringFilters.map((operator) => ({
        ...operator,
        InputComponent: LeaseStatusFilterCell,
      })),
      renderCell: (params: GridRenderCellParams<ReduxLease, LeaseDetailedStatus>) => {
        return <LeaseStatusCell {...params} />;
      },
      sortable: false,
    },
    {
      field: 'moveOutDate',
      type: 'date',
      headerName: 'Move Out',
      align: 'center',
      flex: 1,
      valueFormatter: (value?: string) => {
        return value ? tryFormatDate(value) : '';
      },
      filterOperators: dateFilters,
    },
    {
      field: 'delinquency',
      type: 'number',
      headerName: 'Delinquency',
      flex: 1,
      display: 'flex',
      align: 'center',
      filterable: false,
      renderCell: (params: GridRenderCellParams<ReduxLease, string>) => {
        return <DelinquencyCell {...params} />;
      },
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1.25,
      cellClassName: 'actions',
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];

  return (
    <Box
      sx={{
        height: leasesType === 'all' ? undefined : 500,
        width: 1,
        '& .super-app-theme--inactive': {
          color: 'lightgray',
        },
      }}
    >
      <StripedDataGrid
        disableRowGrouping
        autoHeight={leasesType === 'all'}
        columns={columns}
        rows={rows}
        loading={loading}
        checkboxSelection={leasesType === 'all'}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={onSelectionChange}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        slots={leasesType !== 'renewal' ? { toolbar: ToolbarWithAction } : {}}
        slotProps={{
          toolbar: {
            hideAction: leasesType !== 'all',
            action: (
              <Button
                variant={'contained'}
                onClick={() => dispatch(setOpenEmailDialogAction(EmailType.Bulk))}
                startIcon={<EmailIcon />}
                disabled={selectionModel.length === 0}
              >
                Send Email
              </Button>
            ),
            title: 'Send Email',
          },
          filterPanel: styledFilterPanelProps,
        }}
        onFilterModelChange={onFilterModelChange}
        filterMode={'server'}
        sortingMode={'server'}
        onSortModelChange={onSortModelChange}
        initialState={{
          columns: {
            columnVisibilityModel:
              leasesType !== 'all'
                ? {
                    moveOutDate: false,
                    tenantName: false,
                  }
                : { tenantName: false },
          },
        }}
        getRowClassName={(params) => {
          let classNames = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
          if (
            params.row.status === LeaseDetailedStatus.Closed ||
            params.row.status === LeaseDetailedStatus.ClosedEviction ||
            params.row.status === LeaseDetailedStatus.Cancelled
          ) {
            classNames = classNames + ' inactive';
          }
          return classNames;
        }}
        rowHeight={75}
        pagination
        paginationMode={'server'}
        rowCount={rowCount}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={{ page: page, pageSize: pageSize }}
        onPaginationModelChange={(model: GridPaginationModel) => {
          onPageChange(model.page);
          onPageSizeChange(model.pageSize);
        }}
      />
    </Box>
  );
};
