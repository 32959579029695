import React, { useEffect } from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';

interface TimeSpanPickerProps {
  timeSpan: TimeSpan;
  onChange: (timeSpan: TimeSpan) => void;
}

export interface TimeSpan {
  label: string;
  startDate: Date;
  endDate: Date;
}

export const predefinedSpans: TimeSpan[] = [
  { label: 'Today', startDate: dayjs().toDate(), endDate: dayjs().toDate() },
  { label: 'This Week', startDate: dayjs().startOf('week').toDate(), endDate: dayjs().endOf('week').toDate() },
  {
    label: 'Last Week',
    startDate: dayjs().subtract(1, 'week').startOf('week').toDate(),
    endDate: dayjs().subtract(1, 'week').endOf('week').toDate(),
  },
  { label: 'This Month', startDate: dayjs().startOf('month').toDate(), endDate: dayjs().endOf('month').toDate() },
  {
    label: 'Last Month',
    startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
    endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
  },
  { label: 'This Year', startDate: dayjs().startOf('year').toDate(), endDate: dayjs().endOf('year').toDate() },
  {
    label: 'Last Year',
    startDate: dayjs().subtract(1, 'year').startOf('year').toDate(),
    endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
  },
  { label: 'Custom', startDate: dayjs().toDate(), endDate: dayjs().toDate() },
];

const TimeSpanPicker: React.FC<TimeSpanPickerProps> = ({ onChange, timeSpan }) => {
  const [isCustom, setIsCustom] = React.useState(false);

  useEffect(() => {
    if (isCustom && timeSpan.label === 'Custom') return;
    else if (!isCustom && timeSpan.label === 'Custom') setIsCustom(true);
    else setIsCustom(false);
  }, [timeSpan]);

  const handleSpanChange = (event: SelectChangeEvent<string>) => {
    const selectedLabel = event.target.value;

    const span = predefinedSpans.find((span) => span.label === selectedLabel);
    if (span) {
      onChange({ label: span.label, startDate: span.startDate, endDate: span.endDate });
    }
  };

  const handleCustomDateChange = (start: Date, end: Date) => {
    onChange({ ...timeSpan, startDate: start, endDate: end });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="row" gap={2} flexGrow={1} alignItems="flex-end">
        <Box display={'flex'} flexGrow={1} maxWidth={'50%'} pb={0.5}>
          <FormControl fullWidth>
            <InputLabel>Time Span</InputLabel>
            <Select value={timeSpan.label} onChange={handleSpanChange} label={'Time Span'}>
              {predefinedSpans.map((span) => (
                <MenuItem key={span.label} value={span.label}>
                  {span.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {isCustom && (
          <Box display="flex" flexGrow={1} gap={2} alignItems="flex-end">
            <DatePicker
              label="Start Date"
              value={dayjs(timeSpan.startDate)}
              onChange={(newValue) => newValue && handleCustomDateChange(newValue.toDate(), timeSpan.endDate)}
              slots={{ textField: (params) => <TextField {...params} fullWidth /> }}
              slotProps={{
                popper: {
                  disablePortal: true,
                },
              }}
            />
            <DatePicker
              label="End Date"
              value={dayjs(timeSpan.endDate)}
              onChange={(newValue) => newValue && handleCustomDateChange(timeSpan.startDate, newValue.toDate())}
              slots={{ textField: (params) => <TextField {...params} fullWidth /> }}
              slotProps={{
                popper: {
                  disablePortal: true,
                },
              }}
            />
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default TimeSpanPicker;
