import {
  GridFilterInputValueProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import React, { Ref, useImperativeHandle, useRef } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { ApplicationStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Typography from '@mui/material/Typography';

const appStatusMap = new Map([
  [ApplicationStatus.Approved, 'Approved'],
  [ApplicationStatus.ReadyForReview, 'Ready for Review'],
  [ApplicationStatus.PendingBackgroundCheck, 'Pending with Background Check'],
  [ApplicationStatus.UserEditing, 'User Editing'],
  [ApplicationStatus.Denied, 'Denied'],
  [ApplicationStatus.Withdrawn, 'Withdrawn'],
  [ApplicationStatus.ApprovedWithCosigner, 'Approved with Co-Signer'],
  [ApplicationStatus.Leased, 'Leased'],
]);

export function AppStatusFilterCell(props: GridFilterInputValueProps) {
  const { item, applyValue, focusElementRef } = props;

  const filterRef: Ref<any> = useRef(null);

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      filterRef?.current?.querySelector(`input[value="${item.value || ''}"]`)?.focus();
    },
  }));

  const handleFilterChange = (event: SelectChangeEvent) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <FormControl fullWidth size="small">
      <Select
        id="app-status"
        aria-label="app-status"
        value={item.value ? item.value : ''}
        onChange={(e: SelectChangeEvent) => handleFilterChange(e)}
        ref={filterRef}
      >
        {Array.from(appStatusMap).map(([status, label]) => (
          <MenuItem key={status} dense value={status}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export const AppStatusCustomCell = (props: GridRenderCellParams) => {
  const { value } = props;
  return (
    <>
      {Array.from(appStatusMap).map(([status, label]) =>
        status === value ? (
          <Typography variant="body2" key={status}>
            {label}
          </Typography>
        ) : (
          <Typography key={status}>{''}</Typography>
        ),
      )}
    </>
  );
};

export const AppStatusCustomEditCell = (props: GridRenderEditCellParams) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const isEnabled = (currentStatus: ApplicationStatus, status: ApplicationStatus): boolean => {
    let validStatusChanges: ApplicationStatus[] = [];
    switch (currentStatus) {
      case ApplicationStatus.Approved:
        validStatusChanges = [
          ApplicationStatus.Withdrawn,
          ApplicationStatus.Denied,
          ApplicationStatus.ApprovedWithCosigner,
          ApplicationStatus.ReadyForReview,
        ];
        break;
      case ApplicationStatus.Leased:
        validStatusChanges = [
          ApplicationStatus.Approved,
          ApplicationStatus.Denied,
          ApplicationStatus.ApprovedWithCosigner,
          ApplicationStatus.Withdrawn,
        ];
        break;
      case ApplicationStatus.PendingBackgroundCheck:
        validStatusChanges = [ApplicationStatus.ReadyForReview, ApplicationStatus.Withdrawn];
        break;
      case ApplicationStatus.ApprovedWithCosigner:
        validStatusChanges = [
          ApplicationStatus.Withdrawn,
          ApplicationStatus.Approved,
          ApplicationStatus.ReadyForReview,
          ApplicationStatus.Denied,
        ];
        break;
      case ApplicationStatus.ReadyForReview:
        validStatusChanges = [
          ApplicationStatus.Approved,
          ApplicationStatus.Denied,
          ApplicationStatus.ApprovedWithCosigner,
          ApplicationStatus.UserEditing,
          ApplicationStatus.Withdrawn,
        ];
        break;
      case ApplicationStatus.Denied:
        validStatusChanges = [
          ApplicationStatus.Approved,
          ApplicationStatus.ReadyForReview,
          ApplicationStatus.ApprovedWithCosigner,
        ];
        break;
      case ApplicationStatus.UserEditing:
        validStatusChanges = [
          ApplicationStatus.ReadyForReview,
          // ApplicationStatus.PendingBackgroundCheck,
          ApplicationStatus.Withdrawn,
        ];
        break;
      case ApplicationStatus.Withdrawn:
        validStatusChanges = [ApplicationStatus.ReadyForReview];
        break;
    }

    return validStatusChanges.includes(status);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    apiRef.current?.setEditCellValue({ id, field, value: newValue });
    apiRef.current?.stopCellEditMode({ id, field });
  };

  if (value === 'UserEditing') {
    return (
      <Tooltip title={value}>
        <FormControl fullWidth size="small">
          <Select id="app-status" aria-label="app-status" value={value} onChange={(e) => handleStatusChange(e)}>
            {Array.from(appStatusMap).map(([status, label]) => (
              <MenuItem key={status} dense value={status} disabled={!isEnabled(value, status)}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={value}>
      <FormControl fullWidth size="small">
        <Select id="app-status" aria-label="app-status" value={value} onChange={(e) => handleStatusChange(e)}>
          {Array.from(appStatusMap).map(([status, label]) => (
            <MenuItem key={status} dense value={status} disabled={!isEnabled(value, status)}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Tooltip>
  );
};
