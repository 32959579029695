import React, { FC, FormEvent, useEffect, useState } from 'react';
import {
  ReduxChangeOrder,
  ReduxPurchaseOrder,
  updateEditingPurchaseOrderLineItem,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/expenseTypes';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { addAdjustmentToPurchaseOrderAction, useExpenses } from '../../redux/expenseSlice';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ItemizedPurchaseOrderItemTable } from '../expenseElementTables/ItemizedPurchaseOrderItemTable';
import { POChangeOrder } from './POChangeOrder';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { updateEditingRows } from '../../../utils/updateEditingRows';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { IExpensePurchaseOrderItem } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type POAdjustmentListProps = {
  value: ReduxPurchaseOrder;
  onUpdateChangeOrder: (changeOrder: ReduxChangeOrder) => void;
  onPOLineItemAssociation: (lineItemNumber: string) => void;
  updateGridApiRef?: React.RefObject<GridApiPremium>;
};

const defaultChangeOrder: () => ReduxChangeOrder = () => {
  return {
    note: '',
    amount: 0,
  };
};

export const POAdjustmentList: FC<POAdjustmentListProps> = ({
  value,
  onUpdateChangeOrder,
  onPOLineItemAssociation,
  updateGridApiRef,
}) => {
  const { selectedPurchaseOrder, selectedExpense } = useExpenses();
  const dispatch = useDispatch();
  const [editingChangeOrder, setEditingChangeOrder] = useState<ReduxChangeOrder>(defaultChangeOrder());
  const gridRef = useGridApiRef();

  useEffect(() => {
    if (selectedPurchaseOrder.submitted) {
      setEditingChangeOrder(defaultChangeOrder());
    }
  }, [selectedPurchaseOrder.submitted]);

  const getTotal = (changeOrder: ReduxChangeOrder) => {
    return changeOrder.isItemized
      ? (changeOrder.items?.reduce((acc, curr) => acc + (curr.amount ?? 0) * (curr.quantity ?? 1), 0) ?? 0)
      : changeOrder.amount;
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const updatedChangeOrder = updateEditingRows<ReduxChangeOrder, IExpensePurchaseOrderItem>(
      gridRef,
      editingChangeOrder,
      'items',
      updateEditingPurchaseOrderLineItem,
    );
    (updatedChangeOrder.amount || updatedChangeOrder.items?.length) &&
      selectedExpense.value?.id &&
      value.number &&
      dispatch(
        addAdjustmentToPurchaseOrderAction({
          expenseId: selectedExpense.value?.id,
          poNumber: value.number,
          amount: updatedChangeOrder.isItemized ? undefined : updatedChangeOrder.amount,
          note: updatedChangeOrder.note,
          isItemized: updatedChangeOrder.isItemized ?? false,
          items: updatedChangeOrder.items,
        }),
      );
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <StyledInfoBox label={'Change Orders'}>
          {!value.changeOrders || value.changeOrders?.length === 0 ? (
            <Typography>No current PO Change Orders</Typography>
          ) : (
            <List sx={{ width: '100%' }}>
              {value.changeOrders?.map((x) => (
                <ListItem key={`${x.amount}-${x.dateCreated}-${x.createdBy}`}>
                  <ListItemText
                    primary={
                      <POChangeOrder
                        changeOrder={x}
                        loading={selectedPurchaseOrder.submitting}
                        onUpdateChangeOrder={onUpdateChangeOrder}
                        onPOLineItemAssociation={onPOLineItemAssociation}
                        gridApiRef={updateGridApiRef}
                      />
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </StyledInfoBox>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            <NumberValueTextField
              valueUnits={'dollars'}
              disabled={editingChangeOrder.isItemized}
              fullWidth
              required
              allowNegativeValues
              variant={'outlined'}
              label={editingChangeOrder.isItemized ? 'Total' : 'Amount'}
              value={(getTotal(editingChangeOrder) ?? editingChangeOrder.amount !== 0) ? editingChangeOrder.amount : ''}
              type={'number'}
              onChange={(e) =>
                setEditingChangeOrder({
                  ...editingChangeOrder,
                  amount: Number(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={editingChangeOrder.isItemized ?? false}
                      onChange={(e) =>
                        setEditingChangeOrder({
                          ...editingChangeOrder,
                          isItemized: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Itemized"
                />
              </FormGroup>
            </Box>
          </Grid>
          {editingChangeOrder.isItemized && (
            <Box sx={{ pt: '1rem', width: '100%' }}>
              <ItemizedPurchaseOrderItemTable
                value={editingChangeOrder.items ?? []}
                onSave={(updated) => setEditingChangeOrder({ ...editingChangeOrder, items: updated })}
                loading={false}
                isEditable
                gridApiRef={gridRef}
              />
            </Box>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant={'outlined'}
              multiline
              rows={3}
              label={'Description'}
              value={editingChangeOrder.note ?? ''}
              type={'text'}
              onChange={(e) => setEditingChangeOrder({ ...editingChangeOrder, note: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
              <LoadingButton
                disabled={
                  !editingChangeOrder.amount && !(editingChangeOrder.items && editingChangeOrder.items.length > 0)
                }
                variant={'contained'}
                type={'submit'}
                loading={selectedPurchaseOrder.submitting}
              >
                Add PO Adjustment
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};
